import { Avatar, Flex, HStack, Stack, Tooltip } from '@chakra-ui/react';
import parse from 'html-react-parser';
import compact from 'lodash/compact';

import { IsLoading } from '../../../../types';

import { DotIcon } from '../../../../icons/DotIcon';
import { ExperienceIcon } from '../../../../icons/Placeholders/ExperienceIcon';
import { PendingCompletionIcon } from '../../../../icons/PendingCompletionIcon';

import { EditDeleteButtonGroup } from '../../../../helpers/EditDeleteButtonGroup';
import { Heading } from '../../../../helpers/Heading';
import { Text } from '../../../../helpers/Text';

import { renderLocation } from '../../../common/utils/renderLocation';

import { DateUtils } from '../../../../utils/DateUtils';

import { EditDeleteEmploymentItemEmployment } from './EditDeleteEmploymentItem.types';
import { renderEmploymentJobTitle } from '../../utils/renderEmploymentJobTitle';
import { isEmploymentDetailsComplete } from '../../utils/isEmploymentDetailsComplete';
import { EditEmploymentListItemEmployment } from '../../components/lists/EditEmploymentListItem';

interface EditDeleteEmploymentItemProps {
  employment:
    | EditDeleteEmploymentItemEmployment
    | EditEmploymentListItemEmployment;
  deleteEmploymentIsLoading: IsLoading;
  onEditEmployment: () => void;
  onDeleteEmployment: () => void;
  editable?: boolean;
  deletable?: boolean;
  isEditing?: boolean;
}

function EditDeleteEmploymentItem({
  employment,
  deleteEmploymentIsLoading,
  onEditEmployment,
  onDeleteEmployment,
  editable = true,
  deletable = true,
  isEditing
}: EditDeleteEmploymentItemProps) {
  const location = renderLocation(employment);

  const isIncompleteProfile = !isEmploymentDetailsComplete(
    employment as EditEmploymentListItemEmployment
  );

  return (
    <Tooltip
      w="100%"
      maxW="600"
      isDisabled={isEditing || employment?.description === ''}
      label={
        typeof employment?.description === 'string' &&
        parse(employment?.description)
      }
    >
      <Flex gap={4} alignItems="center">
        <Avatar size="md" icon={<ExperienceIcon />} />

        <Stack spacing={1} flexGrow={1}>
          <Flex
            gap={4}
            alignItems="center"
            justifyContent="space-between"
            w="100%"
          >
            <Heading
              level="h5"
              color="gray.900"
              textTransform="capitalize"
              fontWeight={500}
              noOfLines={1}
            >
              {renderEmploymentJobTitle(employment)}
            </Heading>

            <Flex>
              {isIncompleteProfile && <PendingCompletionIcon />}

              {!isIncompleteProfile && (
                <>
                  {editable && deletable ? (
                    <EditDeleteButtonGroup
                      deleteDisabled={deleteEmploymentIsLoading}
                      onEdit={onEditEmployment}
                      onDelete={onDeleteEmployment}
                    />
                  ) : null}
                </>
              )}

              {(editable && isIncompleteProfile) || (editable && !deletable) ? (
                <EditDeleteButtonGroup
                  onEdit={onEditEmployment}
                  withoutDeleteButton
                />
              ) : null}

              {deletable && !editable ? (
                <EditDeleteButtonGroup
                  deleteDisabled={deleteEmploymentIsLoading}
                  onDelete={onDeleteEmployment}
                  withoutEditButton
                />
              ) : null}
            </Flex>
          </Flex>
          <Flex
            gap={4}
            alignItems="center"
            justifyContent="space-between"
            w="100%"
          >
            <HStack
              spacing={2}
              divider={<DotIcon h="4px" w="4px" color="gray.600" />}
            >
              {employment.company ? (
                <Text textStyle="body1Regular" color="gray.600">
                  {employment.company.name}
                </Text>
              ) : null}
              {location ? (
                <Text textStyle="body1Regular" color="gray.600">
                  {location}
                </Text>
              ) : null}
            </HStack>
            <Text textStyle="body2Regular" color="gray.600" ml="auto">
              {compact([
                DateUtils.formatMonth(employment.startDate),
                employment.currently
                  ? 'PRESENT'
                  : DateUtils.formatMonth(employment.endDate)
              ]).join(' - ')}
            </Text>
          </Flex>
        </Stack>
      </Flex>
    </Tooltip>
  );
}

export default EditDeleteEmploymentItem;
