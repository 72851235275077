import { EditEmploymentListItemEmployment } from '../../components/lists/EditEmploymentListItem';

/**
 * Validates if an employment record has all the required fields properly filled out
 * @param employment The employment record to validate
 * @returns boolean indicating whether the employment record is complete
 */
function isEmploymentDetailsComplete(
  employment: EditEmploymentListItemEmployment
): boolean {
  // Validate each required field individually for better clarity
  const hasJobTitle = Boolean(employment?.jobTitle?.name);
  const hasJobFunctions =
    Array.isArray(employment?.jobFunctions) &&
    employment.jobFunctions.length > 0;
  const hasSpecializations =
    Array.isArray(employment?.specializations) &&
    employment.specializations.length > 0;
  const hasCompany = Boolean(employment?.company?.name);
  const hasCity = Boolean(employment?.city?.name);
  const hasEmploymentType = Boolean(employment?.employmentType);
  const hasStartDate = Boolean(employment?.startDate);

  // Check employment status conditions
  // If currently employed, we don't need end date or reason for leaving
  // If not currently employed, both end date and reason for leaving are required
  const isCurrentlyEmployed = Boolean(employment?.currently);
  const hasPastEmploymentDetails = Boolean(
    employment?.endDate && employment?.reasonForLeaving
  );

  const meetsEmploymentStatusRequirements =
    isCurrentlyEmployed || hasPastEmploymentDetails;

  // All conditions must be true for the employment to be considered complete
  return (
    hasJobTitle &&
    hasJobFunctions &&
    hasSpecializations &&
    hasCompany &&
    hasCity &&
    hasEmploymentType &&
    hasStartDate &&
    meetsEmploymentStatusRequirements
  );
}

export default isEmploymentDetailsComplete;
