import { useCallback, useEffect, useState } from 'react';
import { Stack, SystemProps } from '@chakra-ui/react';

import {
  EmploymentNanoId,
  FetchEmploymentsCacheKey
} from '../../../employmentsTypes';

import { useDeleteEmployment } from '../../../../employments/hooks/useDeleteEmployment';

import { EditDeleteEmploymentItem } from '../../../helpers/EditDeleteEmploymentItem';

import { AddEditEmploymentForm } from '../../forms/AddEditEmploymentForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { EditEmploymentListItemEmployment } from './EditEmploymentListItem.types';

interface EditEmploymentListItemProps {
  employment: EditEmploymentListItemEmployment;
  employmentsCacheKey: FetchEmploymentsCacheKey;
  stackSpacing?: SystemProps['margin'];
  editable?: boolean;
  deletable?: boolean;
  isEditing?: boolean;
  firstIncomleteEmploymentId?: EmploymentNanoId;
}

function EditEmploymentListItem({
  employment,
  employmentsCacheKey,
  stackSpacing = '6',
  editable = true,
  deletable = true,
  isEditing,
  firstIncomleteEmploymentId
}: EditEmploymentListItemProps) {
  const {
    deleteEmployment,
    deleteEmploymentErrorMessage,
    deleteEmploymentIsLoading
  } = useDeleteEmployment({
    employmentNanoId: employment.nanoId,
    cacheKeys: [employmentsCacheKey]
  });

  const [editEmploymentFormIsOpen, setEditEmploymentFormIsOpen] =
    useState<boolean>(false);

  const handleEditEmploymentFormOpen = useCallback(
    () => setEditEmploymentFormIsOpen(true),
    [setEditEmploymentFormIsOpen]
  );

  const handleEditEmploymentFormClose = useCallback(
    () => setEditEmploymentFormIsOpen(false),
    [setEditEmploymentFormIsOpen]
  );

  const handleDeleteEmployment = useCallback<() => void>(
    () => deleteEmployment({}),
    [deleteEmployment]
  );

  useEffect(() => {
    if (
      firstIncomleteEmploymentId &&
      firstIncomleteEmploymentId === employment.nanoId
    )
      setEditEmploymentFormIsOpen(true);
  }, [firstIncomleteEmploymentId, employment]);

  return (
    <Stack spacing={stackSpacing}>
      <AlertMessage message={deleteEmploymentErrorMessage} />
      <EditDeleteEmploymentItem
        employment={employment}
        deleteEmploymentIsLoading={deleteEmploymentIsLoading}
        onDeleteEmployment={handleDeleteEmployment}
        onEditEmployment={handleEditEmploymentFormOpen}
        editable={editable}
        deletable={deletable}
        isEditing={editEmploymentFormIsOpen || isEditing}
      />
      {editEmploymentFormIsOpen && (
        <AddEditEmploymentForm
          type="edit"
          jobResumeRecruiterId={employment.jobResumeRecruiterId}
          employment={employment}
          employmentsCacheKey={employmentsCacheKey}
          isOpen={editEmploymentFormIsOpen}
          onClose={handleEditEmploymentFormClose}
          onSave={handleEditEmploymentFormClose}
        />
      )}
    </Stack>
  );
}

export default EditEmploymentListItem;
